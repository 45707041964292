import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, InputField } from "@unite-us/app-components";

const MetafieldOptions = ({
  onDeleteOption,
  onOptionFieldChange,
  options,
  setOptions,
}) => {
  const addOption = () => {
    setOptions([...options, ""]);
  };

  const removeOption = (i) => {
    const newOptions = [...options];
    newOptions.splice(i, 1);
    setOptions(newOptions);
    onDeleteOption(newOptions);
  };

  return (
    <div className="mb-3 metaFields options">
      {options.map((option, i) => (
        <div key={i}>
          <div className={"flex justify-between"}>
            <div className="flex items-center mt-3 w-5/6">
              <InputField
                id={`option_${i + 1}`}
                label={`Option ${i + 1}`}
                placeholder="Enter the option."
                value={option}
                onChange={(e) => onOptionFieldChange(i, e.target.value)}
                required={options.length === 1}
              />
            </div>
            {options.length > 1 && (
              <div className="flex items-center">
                <button
                  data-testid={`remove-option-${i + 1}`}
                  className=" h-fit mt-6"
                  onClick={() => {
                    removeOption(i);
                  }}
                >
                  <Icon color="text-blue" icon="Delete" />
                </button>
              </div>
            )}
          </div>
        </div>
      ))}
      <Button
        id="add-custom-field-button"
        icon={{ name: "Add", color: "text-blue", position: "left" }}
        className="mb-5 pl-1 text-sm text-blue rounded border-none bg-transparent normal-case add_button"
        label={"ADD OPTION"}
        onClick={() => addOption()}
      />
    </div>
  );
};

MetafieldOptions.propTypes = {
  onDeleteOption: PropTypes.func.isRequired,
  onOptionFieldChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
};

export default MetafieldOptions;
