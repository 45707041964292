import moment from "moment";

const constructFeeScheduleScreeningFormPayload = (
  feeScheduleScreening,
  feeScheduleId,
) => {
  const data = { ...feeScheduleScreening };
  const startsAtMoment = moment.utc(data.starts_at, "MM/DD/YYYY", true);
  const endsAtMoment = moment.utc(data.ends_at, "MM/DD/YYYY", true);

  data.unit_rate = data.unit_rate && Number.parseInt(data.unit_rate, 10);
  data.starts_at = startsAtMoment.isValid()
    ? startsAtMoment.startOf("day").toISOString()
    : data.starts_at;
  data.ends_at = endsAtMoment.isValid()
    ? endsAtMoment.startOf("day").toISOString()
    : data.ends_at;

  data.fee_schedule = { id: feeScheduleId };

  return data;
};

export default constructFeeScheduleScreeningFormPayload;
