import React, { useEffect, useState, useRef } from "react";
import { useInvalidateQueries } from "api/apiHooks.js";
import moment from "moment";
import { Button, useToast } from "@unite-us/app-components";
import { useProviders } from "api/core/providerHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import AddCboTableModal from "../../../components/AddCboTableModal";
import { useFindFeeScheduleProgram } from "api/core/feeScheduleProgramHooks";
import { useCreateProgram } from "api/core/programHooks";
import constructProgramPayload from "../../utils/constructProgramPayload";
import { useParams } from "react-router";

const FeeScheduleProgramCBOTable = () => {
  const feeScheduleProgramId = useParams().feeScheduleProgramId;
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const modalRef = useRef(null);
  const invalidateQueries = useInvalidateQueries();
  const { addToast } = useToast();

  // Will be modified in the future by https://uniteus.atlassian.net/browse/TOOL-1216
  useEffect(() => {
    if (isModalOpen) {
      modalRef.current?.openModal();
    } else {
      modalRef.current?.closeModal();
    }
  }, [isModalOpen]);
  const { createRecord: createProgram } = useCreateProgram({
    onSuccess: () => {
      invalidateQueries("program");
      invalidateQueries("provider");
      addToast({
        message: "CBO was successfully added to this Fee Schedule Program",
        type: "success",
        dataTestId: "add-cbo-success-toast",
      });
    },
    onError: (error) => {
      addToast({
        message: `Error adding CBO to the Fee Schedule Program: ${error.message || "Unknown error"}`,
        type: "error",
        dataTestId: "add-cbo-error-toast",
      });
    },
  });

  const {
    data: feeScheduleProgram,
    isFetching: isFetchingProgram,
    isError: isErrorProgram,
  } = useFindFeeScheduleProgram(feeScheduleProgramId);

  const {
    data: providersResponse,
    isFetching: isFetchingProviders,
    isError: isErrorProviders,
  } = useProviders({
    filters: { fee_schedule_program: feeScheduleProgramId },
    include: "",
    pageSize,
    pageNumber,
  });
  const { data: providers, paging } = providersResponse || {};

  const isFetching = isFetchingProviders || isFetchingProgram;
  const isError = isErrorProviders || isErrorProgram;

  const tableHeaders = [{ label: "Name", className: "w-full" }];

  const tableBody = providers?.map((provider) => ({
    rowId: provider.id,
    rowData: [{ data: provider.name }],
  }));

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const addCBO = async () => {
    if (selectedProvider) {
      const payload = constructProgramPayload(
        feeScheduleProgram,
        selectedProvider,
        feeScheduleProgramId,
      );

      try {
        await createProgram(payload);
      } catch (error) {
        console.error("Error adding CBO to fee schedule program: ", error);
      } finally {
        setSelectedProvider(null);
        closeModal();
      }
    }
  };

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-lg content-center">Community Based Organization</h1>
        <Button
          id="add-cbo-button"
          icon={{ name: "Add", color: "text-blue", position: "left" }}
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
          }
          label="Add CBO"
          data-testid="add-cbo-button"
          onClick={openModal}
          disabled={
            isFetching ||
            moment.utc().isAfter(moment(feeScheduleProgram.ends_at).utc())
          }
        />
      </div>
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        dataTestId="fee-schedule-program-cbo-table"
        emptyTableMessage='Click "Add CBO" to add CBOs'
        errorMessage="Error fetching CBOs."
        className="!h-fit"
        emptyTableClassName="py-4"
      />
      {isModalOpen && (
        <AddCboTableModal
          modalRef={modalRef}
          onCancel={closeModal}
          onConfirm={addCBO}
          existingProviders={providers}
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
        />
      )}
    </>
  );
};

export default FeeScheduleProgramCBOTable;
