import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, useToast } from "@unite-us/app-components";
import { useUpdateFeeScheduleScreening } from "api/core/feeScheduleScreeningHooks";
import { useInvalidateQueries } from "api/apiHooks";

const RemoveFSSProgramsModal = ({
  modalRef,
  closeModal,
  feeScheduleScreening,
  programsToRemove,
  clearSelectionHandler,
}) => {
  const { addToast } = useToast();
  const invalidateQueries = useInvalidateQueries();
  const { updateRecord: updateFeeScheduleScreening } =
    useUpdateFeeScheduleScreening({
      onSuccess: () => {
        invalidateQueries("fee_schedule_screening");
        invalidateQueries("program");
      },
      onError: () => {},
    });

  const removeProviders = async () => {
    if (!programsToRemove) return;
    const programsToRemoveIds = programsToRemove.map((program) => program.id);

    const updatedPrograms = feeScheduleScreening.programs.filter(
      (program) => !programsToRemoveIds.includes(program.id),
    );

    try {
      await updateFeeScheduleScreening(feeScheduleScreening.id, {
        programs: updatedPrograms,
      });
      addToast({
        message: "Fee Schedule Screening Programs removed successfully",
        type: "success",
        dataTestId: "remove-fee-schedule-screening-program-success-toast",
      });
    } catch (error) {
      addToast({
        message: `Error removing Fee Schedule Screening Program: ${error.message || "Unknown error"}`,
        type: "error",
        dataTestId: "remove-fee-schedule-screening-program-error-toast",
      });
      console.error("Error updating fee schedule screening:", error);
    } finally {
      clearSelectionHandler();
    }
  };

  const Footer = () => (
    <div
      className="flex justify-end space-x-6"
      data-testid="remove-fss-programs-modal-footer-container"
    >
      <Button
        id="remove-fss-programs-close-btn"
        className="text-sm text-brand-blue rounded border solid border-med-pale-blue bg-white normal-case"
        label="Cancel"
        data-testid="remove-fss-programs-close-btn"
        onClick={closeModal}
      />
      <Button
        id="remove-fss-programs-remove-btn"
        className="text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case"
        label="Remove"
        data-testid="remove-fss-programs-remove-btn"
        onClick={() => removeProviders()}
      />
    </div>
  );

  return (
    <Modal
      ref={modalRef}
      modalContentStyles="bg-medium-fill-grey overflow-y-hidden grid"
      title={`Remove Programs`}
      data-testid="remove-fss-programs-modal"
      spinnerShow={false}
      onRequestClose={closeModal}
      showBorderRadius={true}
      disableScroll={true}
      modal
      actions={<Footer />}
      size="jumbo"
    >
      <div className="px-1 overflow-y-auto">
        <p className="mb-3">You are about to remove the Programs below.</p>
        <ul className="list-disc pl-6 mb-4">
          {programsToRemove.map((program, index) => (
            <li key={`program-to-remove-${index}`}>{program.name}</li>
          ))}
        </ul>
        <p>Would you like to remove these Programs?</p>
      </div>
    </Modal>
  );
};

RemoveFSSProgramsModal.propTypes = {
  modalRef: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  feeScheduleScreening: PropTypes.object.isRequired,
  programsToRemove: PropTypes.arrayOf(PropTypes.object).isRequired,
  clearSelectionHandler: PropTypes.func.isRequired,
};

export default RemoveFSSProgramsModal;
