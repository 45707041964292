export const toggleSelectAllRows = (
  employees,
  setSelectedRows,
  isAllVisibleRowsSelected,
) => {
  if (isAllVisibleRowsSelected) {
    setSelectedRows([]);
  } else {
    setSelectedRows(employees.map((employee) => employee.id));
  }
};

export const toggleSelectAllRowsSet = (
  rows,
  setSelectedRows,
  isAllVisibleRowsSelected,
) => {
  setSelectedRows((prevState) => {
    const newState = new Set(prevState);

    if (isAllVisibleRowsSelected) {
      rows.forEach((row) => newState.delete(row.id));
      return newState;
    } else {
      rows.forEach((row) => newState.add(row.id));
      return newState;
    }
  });
};
