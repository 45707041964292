const PAYMENT_TYPE_OPTIONS = [
  { label: "Fee for Service", value: "fee_for_service" },
  { label: "Per Member Per Month", value: "per_member_per_month" },
  { label: "Cost Based Reimbursement", value: "cost_based_reimbursement" },
  { label: "Per Diem", value: "per_diem" },
  { label: "Funds Distributed", value: "funds_distributed" },
];
const REQUIRES_UNIT_RATE = [
  "fee_for_service",
  "per_member_per_month",
  "per_diem",
];
const REQUIRES_UNIT_TYPE = [
  "fee_for_service",
  "per_member_per_month",
  "per_diem",
  "funds_distributed",
];
const REQUIRES_CAP_INFO = ["cost_based_reimbursement"];

const STATE_OPTIONS = [
  { label: "Active", value: "active" },
  { label: "Archived", value: "archived" },
];

const SCREENINGS_STATE_OPTIONS = [
  { label: "Active", value: "active" },
  {
    label:
      "Inactive (Fee Schedule Screenings become inactive once their end date has passed)",
    value: "inactive",
  },
  { label: "Archived", value: "archived" },
];

const CODE_TYPE_OPTIONS = [{ label: "LOINC", value: "loinc" }];

const BOOL_OPTIONS = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

const AUTHORIZATION_OPTIONS = [
  { label: "Required", value: "true" },
  { label: "Not Required", value: "false" },
];

const SCC_VALIDATION_OPTIONS = [
  {
    label: "Rigid Validation - Service delivery within SCC Dates",
    value: "false",
  },
  {
    label: "Warning - Allow service delivery dates after SCC End Date",
    value: "true",
  },
];

const PLAN_TYPES = [
  { label: "Medicare", value: "medicare" },
  { label: "Medicaid", value: "medicaid" },
  { label: "Commercial", value: "commercial" },
  { label: "Social", value: "social" },
  { label: "Tricare", value: "tricare" },
];

const ENROLLMENT_OPTIONS = [
  { label: "Required", value: "true" },
  { label: "Not Required", value: "false" },
];

const EMPTY_DRAFT_FIELD = "<p><br></p>";

const planFormConstants = {
  PLAN_TYPES,
  ENROLLMENT_OPTIONS,
};

const feeScheduleProgramFormConstants = {
  PAYMENT_TYPE_OPTIONS,
  REQUIRES_UNIT_RATE,
  REQUIRES_UNIT_TYPE,
  REQUIRES_CAP_INFO,
  STATE_OPTIONS,
  BOOL_OPTIONS,
  AUTHORIZATION_OPTIONS,
  EMPTY_DRAFT_FIELD,
};

const feeScheduleFormConstants = {
  STATE_OPTIONS,
  SCC_VALIDATION_OPTIONS,
};

const feeScheduleScreeningFormConstants = {
  SCREENINGS_STATE_OPTIONS,
  CODE_TYPE_OPTIONS,
};

export {
  feeScheduleFormConstants,
  feeScheduleProgramFormConstants,
  feeScheduleScreeningFormConstants,
  planFormConstants,
};
