import React, { useState } from "react";
import PropTypes from "prop-types";
import PaginatedTable from "components/Tables/PaginatedTable";
import SearchBar from "components/SearchBar/SearchBar";
import { CheckBoxField } from "@unite-us/app-components";
import { toggleSelectAllRowsSet } from "utils/checkboxUtils";
import { usePrograms } from "api/core/programHooks";

const ProgramsFromCBOTable = ({
  org,
  selectedPrograms,
  setSelectedPrograms,
  associatedProgramsIds,
}) => {
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [filters, setFilters] = useState({
    provider: org.id,
  });
  const {
    data: programsResponse,
    isFetching,
    isError,
  } = usePrograms({
    filters: filters,
    include: "",
    pageSize,
    pageNumber,
  });
  const { data: programs, paging } = programsResponse || {};

  const associatedProgramsSet = new Set(associatedProgramsIds);
  const filteredPrograms = programs?.filter(
    (program) => !associatedProgramsSet.has(program.id),
  );

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPageNumber(1);
    setSelectedPrograms(new Set());
  };

  const isAllVisibleRowsSelected =
    filteredPrograms?.every((program) => selectedPrograms.has(program.id)) &&
    filteredPrograms?.length > 0;

  const tableHeaders = [
    {
      label: (
        <CheckBoxField
          id="programs-from-cbo-select-all-checkbox"
          label=""
          hideLabel
          showError={false}
          disabled={false}
          checked={isAllVisibleRowsSelected}
          onChange={() => {
            toggleSelectAllRowsSet(
              filteredPrograms,
              setSelectedPrograms,
              isAllVisibleRowsSelected,
            );
          }}
        />
      ),
      className: "text-xs",
    },
    { label: "Name", classNames: "w-1/3" },
    { label: "Program ID", classNames: "w-1/3" },
  ];

  const tableBody = programs?.map((program) => {
    const isAssociated = associatedProgramsSet.has(program.id);
    return {
      rowId: program.id,
      rowData: [
        {
          data: (
            <CheckBoxField
              id={`select-program-from-cbo-checkbox-${program.id}`}
              label=""
              hideLabel
              showError={false}
              disabled={isAssociated}
              checked={isAssociated || selectedPrograms.has(program.id)}
              onChange={() => {
                setSelectedPrograms((prevState) => {
                  const newState = new Set(prevState);
                  if (newState.has(program.id)) {
                    newState.delete(program.id);
                  } else {
                    newState.add(program.id);
                  }
                  return newState;
                });
              }}
            />
          ),
          onCellClick: (e) => {
            e.stopPropagation();
          },
          className: "text-xs",
        },
        {
          data: program.name,
        },
        { data: program.id },
      ],
    };
  });

  const handleOnSubmitSearchBar = (data) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      query: data?.search,
    }));
    setPageNumber(1);
  };

  return (
    <>
      <span>Select programs to associate with this screening.</span>
      <SearchBar
        onSubmit={handleOnSubmitSearchBar}
        dataTestId="programs-from-cbo-search-bar"
        placeholder="Enter the program name..."
        delay={2000}
        initialValue={filters?.name}
        inputClassName=""
      />
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        setPageSize={handlePageSizeChange}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        dataTestId="programs-from-cbo-table"
        onRowClick={() => {}}
        emptyTableMessage="No Programs found for the Organization."
        emptyTableClassName="min-h-52"
        errorMessage="Error fetching Programs."
        className={
          "flex flex-col min-w-full max-h-[55vh] h-[55vh] sm-h:h-[150px] md-h:h-auto"
        }
      />
    </>
  );
};

ProgramsFromCBOTable.propTypes = {
  org: PropTypes.object.isRequired,
  selectedPrograms: PropTypes.instanceOf(Set).isRequired,
  setSelectedPrograms: PropTypes.func.isRequired,
  associatedProgramsIds: PropTypes.array.isRequired,
};

export default ProgramsFromCBOTable;
