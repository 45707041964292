import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "@unite-us/app-components";
import MetafieldForm from "./MetafieldForm";
import { isEqual } from "lodash";

const EditMetafields = ({
  metafields,
  modalRef,
  confirmationHandler,
  isAttachment,
  closeModal,
}) => {
  const [newMetafields, setNewMetafields] = useState([...metafields]);
  const isSaveDisabled = isEqual(metafields, newMetafields);
  const cancelHandler = () => {
    setNewMetafields([...metafields]);
    closeModal();
  };

  const setNewMetafield = (editedMetafield, index) => {
    const editedMetafields = [...newMetafields];
    editedMetafields[index] = editedMetafield;
    setNewMetafields(editedMetafields);
  };

  const addMetafield = () => {
    const updatedMetafields = [...newMetafields];
    updatedMetafields.push(
      isAttachment
        ? {
            label: "",
            type: "file",
            field: "",
            help_text: "",
            validations: { required: false },
          }
        : {
            label: "",
            type: "",
            field: "",
            help_text: "",
            validations: { required: false },
            options: null,
          },
    );
    setNewMetafields(updatedMetafields);
  };

  const Footer = () => {
    return (
      <div className="flex justify-end space-x-6">
        <Button
          id="cancel-metafields-button"
          className="text-sm text-brand-blue rounded border solid border-med-pale-blue bg-white normal-case"
          label="Cancel"
          data-testid="cancel-metafields-button"
          onClick={cancelHandler}
        />
        <Button
          id="save-metafields-button"
          className="text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case"
          label="Save"
          data-testid="save-metafields-button"
          onClick={() => confirmationHandler(newMetafields)}
          disabled={isSaveDisabled}
        />
      </div>
    );
  };

  return (
    <Modal
      ref={modalRef}
      modalContentStyles="flex-auto overflow-y-auto bg-medium-fill-blue"
      title={`Add/Edit ${isAttachment ? "Attachment" : "Custom Field"}`}
      data-testid="add-edit-metafields-modal"
      spinnerShow={false}
      onRequestClose={cancelHandler}
      modal
      actions={<Footer />}
      size="small"
      focusTrap
      customSize={{
        maxHeight: "100%",
        height: "100%",
        left: "auto",
        right: "0",
        transform: "translateY(-50%)",
        width: "450px",
      }}
    >
      {newMetafields.map((metafield, index) => (
        <MetafieldForm
          key={`metafield-form-${index}`}
          metafield={metafield}
          setMetafield={setNewMetafield}
          index={index}
          isAttachment={isAttachment}
        />
      ))}
      <Button
        id="add-metafield-button"
        icon={{ name: "Add", color: "text-blue", position: "left" }}
        className="py-0 px-4 text-sm !text-text-blue rounded border solid border-light-border-blue bg-white normal-case h-9"
        label={`Add ${isAttachment ? "Attachment" : "Custom Field"}`}
        onClick={() => addMetafield()}
      />
    </Modal>
  );
};

EditMetafields.propTypes = {
  metafields: PropTypes.array.isRequired,
  modalRef: PropTypes.object.isRequired,
  confirmationHandler: PropTypes.func.isRequired,
  isAttachment: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

EditMetafields.defaultProps = {
  isAttachment: false,
};

export default EditMetafields;
