import React from "react";
import { useNavigate, useParams } from "react-router";
import { useCreateFeeScheduleScreening } from "api/core/feeScheduleScreeningHooks";
import constructFeeScheduleScreeningFormPayload from "./utils/constructFeeScheduleScreeningFormPayload";
import FeeScheduleScreeningForm from "components/Forms/FeeScheduleScreeningForm";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import Spinner from "components/Spinner/Spinner";
import { useFindFeeSchedule } from "api/core/feeScheduleHooks";

const CreateFeeScheduleScreening = () => {
  const { createRecord: createFeeScheduleScreening } =
    useCreateFeeScheduleScreening();
  const navigate = useNavigate();
  const { feeScheduleId } = useParams();
  const {
    data: feeSchedule,
    isFetching: isFetchingFeeSchedule,
    isError: isErrorFeeSchedule,
  } = useFindFeeSchedule(feeScheduleId);

  const handleSubmit = async (feeScheduleScreening) => {
    const payload = constructFeeScheduleScreeningFormPayload(
      feeScheduleScreening,
      feeScheduleId,
    );

    try {
      await createFeeScheduleScreening(payload);
      navigate(`/fee-schedules/${feeScheduleId}/fee-schedule-screenings`);
    } catch (error) {
      console.error("Error creating Fee Schedule Screening: ", error);
    }
  };

  return (
    <>
      {isFetchingFeeSchedule && (
        <Spinner
          className="h-screen"
          center
          dataTestId="create-fee-schedule-screening-spinner"
        />
      )}
      {isErrorFeeSchedule && (
        <ErrorMessage
          navigateUrl={`/fee-schedules/${feeScheduleId}/screening`}
          errorText="Error Fetching Fee Schedule"
          dataTestId="fee-schedule-screening-create-error"
          navigateText="Go Back to Fee Schedule Page"
        />
      )}
      {!isErrorFeeSchedule && !isFetchingFeeSchedule && (
        <FeeScheduleScreeningForm
          onSubmit={handleSubmit}
          feeSchedule={feeSchedule}
        />
      )}
    </>
  );
};

export default CreateFeeScheduleScreening;
