import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import { useOutletContext } from "react-router";
import { Button, CheckBoxField, useToast } from "@unite-us/app-components";
import PaginatedTable from "components/Tables/PaginatedTable";
import { useProviders } from "api/core/providerHooks";
import { useUpdateFeeScheduleScreening } from "api/core/feeScheduleScreeningHooks";
import { useInvalidateQueries } from "api/apiHooks";
import AddCboTableModal from "../../../components/AddCboTableModal";

const FeeScheduleScreeningCboTable = () => {
  const feeScheduleScreening = useOutletContext();
  const feeScheduleScreeningProviders = feeScheduleScreening.providers || [];
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [providerToAdd, setProviderToAdd] = useState("");
  const [providersToRemove, setProvidersToRemove] = useState([]);
  const invalidateQueries = useInvalidateQueries();
  const { addToast } = useToast();

  const {
    data: providersResponse,
    isFetching,
    isError,
  } = useProviders({
    filters: { fee_schedule_screenings: feeScheduleScreening.id },
    pageSize,
    pageNumber,
  });

  const { data: providers, paging } = providersResponse || {};

  const { updateRecord: updateFeeScheduleScreening } =
    useUpdateFeeScheduleScreening({
      onSuccess: () => {
        invalidateQueries("fee_schedule_screening");
        invalidateQueries("provider");
      },
      onError: () => {},
    });
  const modalRef = useRef(null);

  const closeAddCboModal = () => setIsModalOpen(false);

  const canRemoveProvider = (providerId) => {
    // NOTE: a provider can only be removed if their programs are not attached to the screening.
    const provider = providers.find((p) => p.id === providerId);
    const providerPrograms = provider?.programs || [];
    const screeningPrograms = feeScheduleScreening.programs || [];

    if (providerPrograms.length === 0 || screeningPrograms.length === 0)
      return true;

    const hasProgramAttached = provider?.programs.some((program) =>
      feeScheduleScreening.programs.some(
        (screeningProgram) => screeningProgram.id === program.id,
      ),
    );

    return !hasProgramAttached;
  };

  const removeProviders = async () => {
    if (!providersToRemove) return;
    providersToRemove.forEach((providerId) => {
      if (!canRemoveProvider(providerId)) {
        addToast({
          message:
            "Cannot remove Community Based Organization(s) with attached programs",
          type: "error",
          dataTestId: "remove-fee-schedule-screening-cbo-error-toast",
        });
        return;
      }
    });
    const updatedProviders = feeScheduleScreeningProviders.filter(
      (provider) => !providersToRemove.includes(provider.id),
    );
    try {
      await updateFeeScheduleScreening(feeScheduleScreening.id, {
        providers: updatedProviders,
      });
      addToast({
        message: "Community Based Organization(s) removed successfully",
        type: "success",
        dataTestId: "remove-fee-schedule-screening-cbo-success-toast",
      });
    } catch (error) {
      addToast({
        message: `Error removing Community Based Organization(s): ${error.message || "Unknown error"}`,
        type: "error",
        dataTestId: "remove-fee-schedule-screening-cbo-error-toast",
      });
      console.error("Error updating fee schedule screening:", error);
    } finally {
      setProvidersToRemove([]);
    }
  };

  const addProviderToFeeScheduleScreening = async () => {
    if (!providerToAdd) return;
    const updatedProviders = [
      ...feeScheduleScreeningProviders,
      { id: providerToAdd },
    ];
    try {
      await updateFeeScheduleScreening(feeScheduleScreening.id, {
        providers: updatedProviders,
      });
      addToast({
        message: "Community Based Organization added successfully",
        type: "success",
        dataTestId: "add-fee-schedule-screening-cbo-success-toast",
      });
    } catch (error) {
      addToast({
        message: `Error adding Community Based Organization: ${error.message || "Unknown error"}`,
        type: "error",
        dataTestId: "add-fee-schedule-screening-cbo-error-toast",
      });
      console.error("Error updating fee schedule screening:", error);
    } finally {
      setIsModalOpen(false);
      setProviderToAdd("");
    }
  };
  // Will be modified in the future by https://uniteus.atlassian.net/browse/TOOL-1216
  useEffect(() => {
    if (isModalOpen) {
      modalRef.current?.openModal();
    } else {
      modalRef.current?.closeModal();
    }
  }, [isModalOpen]);

  const tableHeaders = [
    {
      label: (
        <CheckBoxField
          id="cbo-check-all-checkbox"
          label=""
          hideLabel
          showError={false}
          checked={
            providersToRemove?.length === providers?.length &&
            providers?.length !== 0
          }
          onChange={(e) => {
            if (e.target.checked) {
              setProvidersToRemove(
                providers?.map((provider) => provider.id) || [],
              );
            } else {
              setProvidersToRemove([]);
            }
          }}
        />
      ),
      className: "text-xs",
    },
    { label: "Name", className: "w-full" },
  ];

  const tableBody = providers?.map((provider) => ({
    rowId: provider.id,
    rowData: [
      {
        data: (
          <CheckBoxField
            id={`select-checkbox-${provider.id}`}
            label={`provider ${provider.id}`}
            hideLabel
            showError={false}
            checked={providersToRemove?.includes(provider.id)}
            onChange={() => {
              setProvidersToRemove((prevState) =>
                prevState.includes(provider.id)
                  ? prevState.filter((id) => id !== provider.id)
                  : [...prevState, provider.id],
              );
            }}
          />
        ),
        className: "text-xs",
      },
      { data: provider.name },
    ],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue rounded-t-md">
        <h1 className="text-lg content-center">
          Community Based Organizations
        </h1>
        <div className="flex flex-row space-x-4">
          <Button
            id="add-fee-schedule-screening-cbo-button"
            icon={{ name: "Add", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Add CBO"
            data-testid="add-fee-schedule-screening-cbo-button"
            onClick={() => setIsModalOpen(true)}
            disabled={
              isFetching ||
              moment.utc().isAfter(moment(feeScheduleScreening.ends_at).utc())
            }
          />
          <Button
            id="remove-fee-schedule-screening-cbo-button"
            icon={{ name: "MinusCircle", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Remove CBO"
            data-testid="remove-fee-schedule-screening-cbo-button"
            onClick={() => removeProviders()}
            disabled={
              !providersToRemove?.length ||
              isFetching ||
              moment.utc().isAfter(moment(feeScheduleScreening.ends_at).utc())
            }
          />
        </div>
      </div>
      <PaginatedTable
        headers={tableHeaders}
        body={tableBody}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        paging={paging}
        dataTestId="fee-schedule-screening-cbo-table"
        emptyTableMessage="Click 'Add CBO' to add CBOs"
        errorMessage="Error Fetching Community Based Organizations"
        className="!h-fit"
        emptyTableClassName="py-4"
      />
      {isModalOpen && (
        <AddCboTableModal
          modalRef={modalRef}
          existingProviders={providers}
          onCancel={closeAddCboModal}
          onConfirm={addProviderToFeeScheduleScreening}
          selectedProvider={providerToAdd}
          setSelectedProvider={setProviderToAdd}
        />
      )}
    </>
  );
};

export default FeeScheduleScreeningCboTable;
