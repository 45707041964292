import React from "react";
import { useOutletContext } from "react-router";
import AttachmentsTable from "components/Metafields/AttachmentsTable";
import CustomFieldsTable from "components/Metafields/CustomFieldsTable";
import { useUpdateFeeScheduleProgram } from "api/core/feeScheduleProgramHooks";

const CustomFieldsAttachments = () => {
  const feeScheduleProgram = useOutletContext();
  const { updateRecord: updateFeeScheduleProgram } =
    useUpdateFeeScheduleProgram();

  return (
    <div className="justify-between space-y-8">
      <CustomFieldsTable
        entity={feeScheduleProgram}
        updateEntity={updateFeeScheduleProgram}
      />
      <AttachmentsTable
        entity={feeScheduleProgram}
        updateEntity={updateFeeScheduleProgram}
      />
    </div>
  );
};

export default CustomFieldsAttachments;
