const constructProgramPayload = (
  feeScheduleProgram,
  selectedProviderId,
  feeScheduleProgramId,
) => {
  const { name, description, eligibility_text } = feeScheduleProgram;

  const data = {
    name: name || "Please insert a new program name",
    description: description || "Please insert a new program description",
    eligibility_text:
      eligibility_text || "Please insert a new eligibility text",
    fee_schedule_program: { id: feeScheduleProgramId },
    provider: { id: selectedProviderId },
  };

  return data;
};

export default constructProgramPayload;
