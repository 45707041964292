import {
  useDefaultMutationConfig,
  useDefaultPagingQueryConfig,
} from "api/apiUtils.js";
import {
  useFind,
  useCreateRecord,
  useInvalidateQueries,
} from "api/apiHooks.js";
import { getJsonApiAdapter } from "api/apiService.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import { useToast } from "@unite-us/app-components";

export const usePrograms = ({
  filters = {},
  include = "",
  pageSize = 25,
  pageNumber = 1,
  sort = "",
}) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind(
    "program",
    {
      ...filters,
    },
    jsonApiAdapter,
    {
      queryConfig: { ...defaultPagingQueryConfig },
      page: { number: pageNumber, size: pageSize },
      sort: sort,
      include: include,
    },
  );
};

export const useCreateProgram = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useCreateRecord("program", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      onSuccess: () => {
        invalidateQueries("program");
        invalidateQueries("provider");
        addToast({
          message: "Program created successfully",
          type: "success",
          dataTestId: "create-program-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error creating program: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "create-program-error-toast",
        });
      },
      ...mutationConfig,
    },
  });
};
