import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Icon } from "@unite-us/app-components";
import PaginatedTable from "components/Tables/PaginatedTable";
import SearchBar from "components/SearchBar/SearchBar";
import { useProviders } from "api/core/providerHooks";

const AddCboTableModal = ({
  modalRef,
  onConfirm,
  onCancel,
  existingProviders,
  selectedProvider,
  setSelectedProvider,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const existingProvidersIds = new Set(
    existingProviders?.map((provider) => provider.id),
  );
  const isSaveDisabled = !selectedProvider;
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: providersResponse,
    isFetching,
    isError,
  } = useProviders({ filters: { name: searchTerm }, pageNumber, pageSize });
  const { data: providers, paging } = providersResponse || {};
  const handleSearch = (data) => {
    setPageNumber(1);
    setSearchTerm(data?.search);
  };

  const tableHeaders = [
    { label: "Name", className: "w-1/3" },
    { label: "Organization ID", className: "w-1/3" },
    { label: "Included", className: "w-1/3" },
  ];
  const tableBody = providers?.map((provider, index) => {
    const isSelected = existingProvidersIds.has(provider.id);
    return {
      rowId: provider.id,
      rowClassName: `${
        selectedProvider === provider.id
          ? "hover:bg-dark-fill-blue bg-dark-fill-blue"
          : ""
      }${isSelected ? "disabled" : ""}`,
      rowData: [
        { data: provider.name },
        { data: provider.id },
        {
          data:
            selectedProvider === provider.id || isSelected ? (
              <Icon icon="CheckCircle" size={20} />
            ) : undefined,
        },
      ],
    };
  });
  const Footer = () => (
    <div className="flex justify-end space-x-6">
      <Button
        id="cancel-add-cbo-button"
        className="text-sm text-brand-blue rounded border solid border-med-pale-blue bg-white normal-case"
        label="Cancel"
        data-testid="cancel-add-cbo-button"
        onClick={onCancel}
      />
      <Button
        id="confirm-add-cbo-button"
        className="text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case"
        label="Save"
        data-testid="confirm-add-cbo-button"
        onClick={onConfirm}
        disabled={isSaveDisabled}
      />
    </div>
  );

  return (
    <Modal
      ref={modalRef}
      actions={<Footer />}
      modalContentStyles="overflow-y-auto grid"
      title="Add New Community Based Organization"
      onRequestClose={onCancel}
      size="jumbo"
      disableScroll={true}
      modal
    >
      <>
        <SearchBar
          onSubmit={handleSearch}
          inputClassName="w-full"
          placeholder="Enter the Organization Name..."
        />
        <PaginatedTable
          headers={tableHeaders}
          isError={isError}
          isFetching={isFetching}
          body={tableBody}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          paging={paging}
          dataTestId="add-cbo-table"
          emptyTableMessage="No organizations found"
          className={
            "flex flex-col min-w-full max-h-[55vh] h-[55vh] sm-h:h-[150px] md-h:h-auto"
          }
          errorMessage="Error fetching organizations"
          onRowClick={(providerId) => {
            if (!existingProvidersIds.has(providerId))
              setSelectedProvider(providerId);
          }}
        />
      </>
    </Modal>
  );
};

AddCboTableModal.propTypes = {
  modalRef: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  existingProviders: PropTypes.array.isRequired,
  selectedProvider: PropTypes.string,
  setSelectedProvider: PropTypes.func.isRequired,
};
PropTypes.defaultProps = {
  selectedProvider: "",
};

export default AddCboTableModal;
