import React, { useState, useEffect, useRef } from "react";
import { usePrograms } from "api/core/programHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import { Button, CheckBoxField } from "@unite-us/app-components";
import AddFSSProgramsModal from "./components/AddFSSProgramsModal";
import RemoveFSSProgramsModal from "./components/RemoveFSSProgramsModal";
import { useOutletContext } from "react-router";
import moment from "moment";

const FeeScheduleScreeningPrograms = () => {
  const feeScheduleScreening = useOutletContext();
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const {
    data: programsResponse,
    isFetching,
    isError,
  } = usePrograms({
    filters: { fee_schedule_screenings: feeScheduleScreening.id },
    pageSize: pageSize,
    pageNumber: pageNumber,
    include: "provider",
  });
  const { data: programs, paging } = programsResponse || {};
  const [programsToRemoveIds, setProgramsToRemoveIds] = useState([]);
  const [programsToRemove, setProgramsToRemove] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const addModalRef = useRef(null);
  const removeModalRef = useRef(null);

  // Will be modified in the future by https://uniteus.atlassian.net/browse/TOOL-1216
  useEffect(() => {
    if (isAddModalOpen) {
      addModalRef.current?.openModal();
    } else {
      addModalRef.current?.closeModal();
    }
  }, [isAddModalOpen]);

  useEffect(() => {
    if (isRemoveModalOpen) {
      removeModalRef.current?.openModal();
    } else {
      removeModalRef.current?.closeModal();
    }
  }, [isRemoveModalOpen]);

  const openAddModal = () => setIsAddModalOpen(true);
  const closeAddModal = () => setIsAddModalOpen(false);

  const openRemoveModal = () => {
    setProgramsToRemove(
      programs?.filter((program) => programsToRemoveIds?.includes(program.id)),
    );
    setIsRemoveModalOpen(true);
  };
  const closeRemoveModal = () => setIsRemoveModalOpen(false);

  const clearSelectionHandler = () => {
    setProgramsToRemoveIds([]);
    setProgramsToRemove([]);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPageNumber(1);
    clearSelectionHandler();
  };

  const tableHeaders = [
    {
      label: (
        <CheckBoxField
          id="screening-programs-check-all-checkbox"
          label=""
          hideLabel
          showError={false}
          checked={
            programsToRemoveIds?.length === programs?.length &&
            programs?.length !== 0
          }
          onChange={(e) => {
            if (e.target.checked) {
              setProgramsToRemoveIds(
                programs?.map((provider) => provider.id) || [],
              );
            } else {
              setProgramsToRemoveIds([]);
            }
          }}
        />
      ),
      className: "text-xs",
    },
    { label: "Program Name", className: "w-1/3" },
    { label: "Program ID", className: "w-1/3" },
    { label: "Organization Name", className: "w-1/3" },
  ];

  const tableBody = programs?.map((program) => ({
    rowId: program.id,
    rowData: [
      {
        data: (
          <CheckBoxField
            id={`select-screening-program-checkbox-${program.id}`}
            label={`program ${program.id}`}
            hideLabel
            showError={false}
            checked={programsToRemoveIds?.includes(program.id)}
            onChange={() => {
              setProgramsToRemoveIds((prevState) =>
                prevState.includes(program.id)
                  ? prevState.filter((id) => id !== program.id)
                  : [...prevState, program.id],
              );
            }}
          />
        ),
        className: "text-xs",
      },
      { data: program.name },
      { data: program.id },
      { data: program?.provider?.name },
    ],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue rounded-t-md">
        <h1 className="text-lg content-center">Programs</h1>
        <div className="flex flex-row space-x-4">
          <Button
            id="add-fee-schedule-screening-program-button"
            icon={{ name: "Add", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Add Program"
            data-testid="add-fee-schedule-screening-program-button"
            onClick={() => openAddModal()}
            disabled={
              feeScheduleScreening?.providers?.length === 0 ||
              isFetching ||
              moment.utc().isAfter(moment.utc(feeScheduleScreening.ends_at))
            }
          />
          <Button
            id="remove-fee-schedule-screening-program-button"
            icon={{ name: "MinusCircle", color: "text-blue", position: "left" }}
            className={
              "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
            }
            label="Remove Program"
            data-testid="remove-fee-schedule-screening-program-button"
            onClick={() => openRemoveModal()}
            disabled={
              !programsToRemoveIds?.length ||
              isFetching ||
              moment.utc().isAfter(moment.utc(feeScheduleScreening.ends_at))
            }
          />
        </div>
      </div>
      <PaginatedTable
        headers={tableHeaders}
        body={tableBody}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        setPageSize={handlePageSizeChange}
        paging={paging}
        dataTestId="fee-schedule-screening-programs-table"
        emptyTableMessage={`No programs linked to this Fee Schedule Screening.
        Click “Add Program” to add a program to this Fee Schedule Screening.`}
        errorMessage="Error Fetching Fee Schedule Screening Programs"
        className="!h-fit"
        emptyTableClassName="py-4"
      />
      {isAddModalOpen && (
        <AddFSSProgramsModal
          modalRef={addModalRef}
          closeModal={closeAddModal}
          feeScheduleScreening={feeScheduleScreening}
          associatedPrograms={programs}
        />
      )}
      {isRemoveModalOpen && (
        <RemoveFSSProgramsModal
          modalRef={removeModalRef}
          closeModal={closeRemoveModal}
          feeScheduleScreening={feeScheduleScreening}
          programsToRemove={programsToRemove}
          clearSelectionHandler={clearSelectionHandler}
        />
      )}
    </>
  );
};

export default FeeScheduleScreeningPrograms;
