import React, { useState } from "react";
import PropTypes from "prop-types";
import { snakeCase } from "lodash";
import {
  BaseCard,
  BaseCardBody,
  BaseCardHeader,
  RadioField,
  TextField,
  InputField,
} from "@unite-us/app-components";
import { SelectField } from "@unite-us/ui";
import MetafieldOptions from "./MetafieldOptions";

const MetafieldForm = ({ metafield, setMetafield, index, isAttachment }) => {
  const [tempMetafield, setTempMetafield] = useState({ ...metafield });
  const shouldInitiallyShowOptions =
    tempMetafield?.type === "dropdown" ||
    tempMetafield?.type === "multipledropdown";
  const [showOptions, setShowOptions] = useState(shouldInitiallyShowOptions);
  const [options, setOptions] = useState(tempMetafield.options ?? ["", "", ""]);

  const addOptionsToMetaFields = (newOptions) => {
    const validOptions = newOptions.filter((o) => o !== "");
    return { ...tempMetafield, ...{ options: validOptions } };
  };

  const removeOptionsFromMetaFields = (newField) =>
    newField.type && !["dropdown", "multipledropdown"].includes(newField.type)
      ? { ...tempMetafield, options: null }
      : { ...tempMetafield };

  const onFieldChange = (newField) => {
    const newMetafield = {
      ...removeOptionsFromMetaFields(newField),
      ...newField,
    };
    if (newMetafield.help_text === "") {
      delete newMetafield.help_text;
    }
    setTempMetafield(newMetafield);
    setMetafield(newMetafield, index);
  };

  const onOptionFieldChange = (i, newValue) => {
    const newOptions = [...options];
    newOptions.splice(i, 1, newValue);
    setOptions(newOptions);
    const newMetafield = addOptionsToMetaFields(newOptions);
    setTempMetafield(newMetafield);
    setMetafield(newMetafield, index);
  };

  const onDeleteOption = (newOptions) => {
    const newMetafield = addOptionsToMetaFields(newOptions);
    setTempMetafield(newMetafield);
    setMetafield(newMetafield, index);
  };

  const shouldShowOptions = (value) => {
    setShowOptions(value === "multipledropdown" || value === "dropdown");
  };

  const requirementOptions = [
    { label: "Required", value: true },
    { label: "Optional", value: false },
  ];
  const typeOptions = [
    { label: "Text", value: "text" },
    { label: "Number", value: "number" },
    { label: "Money", value: "money" },
    { label: "File", value: "file" },
    { label: "Address", value: "address" },
    { label: "Date", value: "date" },
    { label: "Date and Time", value: "datetime" },
    { label: "Single-select Dropdown", value: "dropdown" },
    { label: "Multi-select Dropdown", value: "multipledropdown" },
  ];

  return (
    <BaseCard className="mb-4">
      <BaseCardHeader
        title={
          isAttachment ? `Attachment ${index + 1}` : `Custom Field ${index + 1}`
        }
        className="!font-bold text-text-blue text-lg"
      />
      <BaseCardBody withPadding className="gap-y-4">
        <InputField
          id={`field-name-${index}`}
          label="Field Name"
          labelClassName="!text-xs !text-[#506784] tracking-[1px] !leading-[18px] font-heavy-font !uppercase"
          placeholder="Enter the name of the field"
          type="text"
          value={tempMetafield.label}
          required
          dataTestId={`metafield-name-${index}`}
          onChange={(e) => {
            onFieldChange({
              field: snakeCase(e.target.value),
              label: e.target.value,
            });
          }}
        />
        <div className={"flex justify-between gap-x-2"}>
          {!isAttachment && (
            <SelectField
              id={`field-type-${index}`}
              label="Field Type"
              labelClassName="!text-xs !text-[#506784] tracking-[1px] !leading-[18px] font-heavy-font !uppercase"
              className="pt-4 h-fit"
              required
              options={typeOptions}
              value={tempMetafield.type}
              valueKey="value"
              labelKey="label"
              dataTestId={`metafield-type-${index}`}
              onChange={(value) => {
                onFieldChange({ type: value });
                shouldShowOptions(value);
              }}
            />
          )}
          <RadioField
            id={`required-${index}`}
            inline
            label="Field Requirement"
            labelClassName="!text-xs !text-[#506784] tracking-[1px] !leading-[18px] font-heavy-font !uppercase pt-2"
            options={requirementOptions}
            value={
              tempMetafield.validations
                ? tempMetafield.validations.required
                : false
            }
            dataTestId={`metafield-requirement-${index}`}
            className="pt-2"
            onChange={(e) => {
              onFieldChange({
                validations: {
                  ...tempMetafield.validations,
                  required: e.target.value === "true",
                },
              });
            }}
          />
        </div>
        {showOptions && (
          <MetafieldOptions
            onDeleteOption={onDeleteOption}
            onOptionFieldChange={onOptionFieldChange}
            options={options}
            setOptions={setOptions}
          />
        )}
        <TextField
          id={`description-${index}`}
          label="Description"
          labelClassName="!text-xs !text-[#506784] tracking-[1px] !leading-[18px] font-heavy-font !uppercase"
          className="pt-2"
          placeholder="Type Description here."
          dataTestId={`metafield-description-${index}`}
          value={tempMetafield.help_text}
          onChange={(e) => onFieldChange({ help_text: e.target.value })}
        />
      </BaseCardBody>
    </BaseCard>
  );
};

MetafieldForm.propTypes = {
  metafield: PropTypes.shape({
    field: PropTypes.string,
    label: PropTypes.string,
    help_text: PropTypes.string,
    validations: PropTypes.shape({
      required: PropTypes.bool,
    }),
  }).isRequired,
  setMetafield: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isAttachment: PropTypes.bool,
};

MetafieldForm.defaultProps = {
  isAttachment: false,
  metafield: {
    field: "",
    label: "",
    help_text: "",
    validations: {
      required: false,
    },
  },
};

export default MetafieldForm;
