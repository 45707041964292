import React from "react";
import PropTypes from "prop-types";
import NavigationHeader from "components/NavigationHeader";
import { Form, Field } from "react-final-form";
import {
  isInteger,
  isRequired,
  validateFSPEndDate,
  validateFSPStartDate,
} from "utils/validations";
import { Button, InputField, RadioField } from "@unite-us/app-components";
import { SelectField, DateField } from "@unite-us/ui";
import { feeScheduleScreeningFormConstants } from "./utils/constants";
import moment from "moment";
import { useNavigate, useParams } from "react-router";

const getMinDate = (pickedStartDate, feeScheduleStartsAt) => {
  const pickedStartMoment = moment.utc(pickedStartDate, "MM/DD/YYYY");
  if (pickedStartMoment.isAfter(moment.utc(feeScheduleStartsAt))) {
    return pickedStartMoment.add("12", "h").format("MM/DD/YYYY");
  }
  return moment.utc(feeScheduleStartsAt).add("12", "h").format("MM/DD/YYYY");
};

const FeeScheduleScreeningForm = ({ onSubmit, initialValues, feeSchedule }) => {
  const navigate = useNavigate();
  const { feeScheduleId, feeScheduleScreeningId } = useParams();
  const { SCREENINGS_STATE_OPTIONS, CODE_TYPE_OPTIONS } =
    feeScheduleScreeningFormConstants;

  const isEditing = !!feeScheduleScreeningId;

  const backRedirection = isEditing
    ? `/fee-schedules/${feeScheduleId}/fee-schedule-screenings/${feeScheduleScreeningId}`
    : `/fee-schedules/${feeScheduleId}/fee-schedule-screenings`;

  const namespace = "fee-schedule-screening-form";

  const onCancel = () => navigate(backRedirection);
  const feeScheduleStartsAt =
    feeSchedule.starts_at ?? moment.utc().startOf("day").toISOString();
  const feeScheduleEndsAt =
    feeSchedule.ends_at ??
    moment.utc().startOf("day").add("7", "days").toISOString();

  return (
    <div className="flex flex-col items-center justify-center w-full pb-4">
      <NavigationHeader
        backButtonRedirectTo={backRedirection}
        dataTestId={`${namespace}-navigation-header`}
      />
      <div className="flex flex-row justify-start w-2/5 min-w-80 py-4">
        <h1 className="text-xl font-semibold">
          {isEditing
            ? "Edit Fee Schedule Screening"
            : "Add New Fee Schedule Screening"}
        </h1>
      </div>
      <div className="border-2 rounded bg-medium-fill-grey p-8 w-2/5 min-w-80">
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Field name="name" validate={(val) => isRequired(val)}>
                {(props) => (
                  <div className="h-20">
                    <InputField
                      id={`${namespace}__name`}
                      label="Name"
                      omitLabelStyles
                      dataTestId={`${namespace}-name`}
                      className="w-full mb-0.5"
                      required
                      {...props}
                    />
                  </div>
                )}
              </Field>
              <div className="flex w-full gap-x-6">
                <Field
                  name="starts_at"
                  className="w-1/2"
                  validate={(val) =>
                    isRequired(val) ||
                    validateFSPStartDate(
                      val,
                      values.ends_at,
                      feeScheduleStartsAt,
                    )
                  }
                >
                  {({ input, meta }) => (
                    <DateField
                      className="w-1/2"
                      id={`${namespace}__starts-at`}
                      label="Start Date"
                      dataTestId={`${namespace}-starts-at`}
                      minDate={moment.utc().startOf("day")}
                      maxDate={moment
                        .utc(
                          values?.ends_at
                            ? moment(values.ends_at, "MM/DD/YYYY").format(
                                "YYYY-MM-DD",
                              )
                            : feeScheduleEndsAt,
                        )
                        .subtract(1, "day")
                        .format("MM/DD/YYYY")}
                      required
                      valueFormat="MM/DD/YYYY"
                      {...meta}
                      onChange={(e) => {
                        input.onChange(e);
                      }}
                      value={initialValues?.starts_at}
                    />
                  )}
                </Field>
                <Field
                  className="w-1/2"
                  name="ends_at"
                  validate={(val) =>
                    isRequired(val) ||
                    validateFSPEndDate(val, values.starts_at, feeScheduleEndsAt)
                  }
                >
                  {({ input, meta }) => (
                    <DateField
                      className="w-1/2"
                      id={`${namespace}__ends-at`}
                      label="End Date"
                      dataTestId={`${namespace}-ends-at`}
                      minDate={getMinDate(
                        values.starts_at,
                        feeScheduleStartsAt,
                      )}
                      maxDate={moment
                        .utc(feeScheduleEndsAt)
                        .format("MM/DD/YYYY")}
                      required
                      valueFormat="MM/DD/YYYY"
                      {...meta}
                      onChange={(e) => {
                        input.onChange(e);
                      }}
                      value={initialValues?.ends_at}
                    />
                  )}
                </Field>
              </div>
              <Field
                name="state"
                validate={(val) => isRequired(val)}
                defaultValue="active"
              >
                {({ input, meta }) => (
                  <RadioField
                    type="radio"
                    className="pb-4"
                    id={`${namespace}__state`}
                    dataTestId={`${namespace}-state`}
                    label="Status"
                    labelClassName="!text-xs !text-[#506784] tracking-[1px] !leading-[18px] font-heavy-font !uppercase"
                    options={SCREENINGS_STATE_OPTIONS}
                    required
                    input={{
                      ...input,
                      onChange: (e, disabled) => {
                        input.onChange(e.target.value, disabled);
                      },
                    }}
                    {...meta}
                  />
                )}
              </Field>
              <Field
                name="unit_rate"
                validate={(val) => isRequired(val) || isInteger(val)}
              >
                {(props) => (
                  <div className="h-20">
                    <InputField
                      id={`${namespace}__unit_rate`}
                      label="Unit Rate"
                      className="w-full"
                      omitLabelStyles
                      dataTestId={`${namespace}-unit-rate`}
                      required
                      {...props}
                    />
                  </div>
                )}
              </Field>
              <div className="flex w-full gap-x-6">
                <Field
                  name="code_type"
                  validate={(val) => isRequired(val)}
                  defaultValue={"loinc"}
                >
                  {(props) => (
                    <SelectField
                      id={`${namespace}__code_type`}
                      label="Code Type"
                      dataTestId={`${namespace}-code-type`}
                      className="w-full"
                      required
                      clearable={false}
                      searchEnabled={false}
                      options={CODE_TYPE_OPTIONS}
                      {...props}
                    />
                  )}
                </Field>
                <Field name="code" validate={(val) => isRequired(val)}>
                  {(props) => (
                    <InputField
                      id={`${namespace}__code`}
                      label="Code"
                      omitLabelStyles
                      dataTestId={`${namespace}-code`}
                      className="w-full mb-0.5"
                      required
                      {...props}
                    />
                  )}
                </Field>
              </div>
              <div
                className="flex justify-end gap-x-4 pt-8"
                data-testid={`${namespace}-buttons-container`}
              >
                <Button
                  className="w-32"
                  data-testid="cancel-fee-schedule-screening-form-btn"
                  label="Cancel"
                  secondary
                  onClick={onCancel}
                />
                <Button
                  className="w-32"
                  label={isEditing ? "Update" : "Create"}
                  primary
                  type="submit"
                />
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
};

FeeScheduleScreeningForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  feeSchedule: PropTypes.object.isRequired,
};

FeeScheduleScreeningForm.defaultProps = {
  initialValues: {},
};

export default FeeScheduleScreeningForm;
