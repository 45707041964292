import React, { useState } from "react";
import PropTypes from "prop-types";
import { useProviders } from "api/core/providerHooks";
import PaginatedTable from "components/Tables/PaginatedTable";
import SearchBar from "components/SearchBar/SearchBar";

const FSSOrganizationsTable = ({
  selectedOrg,
  setSelectedOrg,
  feeScheduleScreeningId,
}) => {
  const [pageSize, setPageSize] = useState(100);
  const [pageNumber, setPageNumber] = useState(1);
  const [filters, setFilters] = useState({
    fee_schedule_screenings: feeScheduleScreeningId,
  });
  const {
    data: organizationResponse,
    isFetching,
    isError,
  } = useProviders({
    filters: filters,
    include: "",
    pageSize,
    pageNumber,
  });
  const { data: organizations, paging } = organizationResponse || {};

  const tableHeaders = [
    { label: "Name", classNames: "w-1/2" },
    { label: "Organization ID", classNames: "w-1/2" },
  ];

  const tableBody = organizations?.map((organization) => {
    return {
      rowId: organization.id,
      rowClassName:
        selectedOrg.id === organization.id
          ? "bg-pale-grey hover:bg-pale-grey"
          : "",
      rowData: [{ data: organization.name }, { data: organization.id }],
    };
  });

  const handleOnSubmitSearchBar = (data) => {
    const nameFilter = data?.search === "" ? {} : { name: data?.search };
    setFilters(({ name, ...prevFilters }) => ({
      ...prevFilters,
      ...nameFilter,
    }));
    setPageNumber(1);
  };

  const onRowClickHandler = (orgId) => {
    const org = organizations.find((org) => org.id === orgId);
    setSelectedOrg(org);
  };

  return (
    <>
      <span className="pb-2">
        Select one of the CBOs associated with this screening in order to view
        programs.
      </span>
      <SearchBar
        onSubmit={handleOnSubmitSearchBar}
        dataTestId="fss-organizations-search-bar"
        placeholder="Enter Organization Name..."
        delay={2000}
        initialValue={filters?.name}
        inputClassName=""
      />
      <PaginatedTable
        body={tableBody}
        headers={tableHeaders}
        isFetching={isFetching}
        isError={isError}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        paging={paging}
        dataTestId="fss-organizations-table"
        emptyTableMessage="No Organizations Found."
        emptyTableClassName="min-h-52"
        errorMessage="Error fetching Organizations."
        className={
          "flex flex-col min-w-full max-h-[55vh] h-[55vh] sm-h:h-[150px] md-h:h-auto"
        }
        onRowClick={onRowClickHandler}
      />
    </>
  );
};

FSSOrganizationsTable.propTypes = {
  selectedOrg: PropTypes.object.isRequired,
  setSelectedOrg: PropTypes.func.isRequired,
  feeScheduleScreeningId: PropTypes.string.isRequired,
};

export default FSSOrganizationsTable;
