import { useToast } from "@unite-us/app-components";
import { getJsonApiAdapter } from "api/apiService.js";
import {
  useDefaultPagingQueryConfig,
  useDefaultQueryConfig,
  useDefaultMutationConfig,
} from "api/apiUtils.js";
import { useAuthContext } from "components/auth/AuthContextProvider.js";
import {
  useFind,
  useFindRecord,
  useCreateRecord,
  useUpdateRecord,
  useInvalidateQueries,
} from "api/apiHooks.js";

export const useFeeScheduleScreenings = ({
  filters = {},
  pageSize,
  pageNumber,
  sort,
}) => {
  const { authToken } = useAuthContext();
  const defaultPagingQueryConfig = useDefaultPagingQueryConfig();
  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFind("fee_schedule_screening", { ...filters }, jsonApiAdapter, {
    queryConfig: { ...defaultPagingQueryConfig },
    page: { number: pageNumber || 1, size: pageSize || 15 },
    sort: sort || "",
  });
};

export const useFindFeeScheduleScreening = (feeScheduleScreeningId) => {
  const { authToken } = useAuthContext();
  const defaultQueryConfig = useDefaultQueryConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useFindRecord(
    "fee_schedule_screening",
    feeScheduleScreeningId,
    jsonApiAdapter,
    {
      queryConfig: defaultQueryConfig,
    },
  );
};

export const useCreateFeeScheduleScreening = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useCreateRecord("fee_schedule_screening", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      onSuccess: () => {
        invalidateQueries("fee_schedule_screening");
        addToast({
          message: "Fee Schedule Screening created successfully",
          type: "success",
          dataTestId: "create-fee-schedule-screening-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error creating Fee Schedule Screening: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "create-fee-schedule-screening-error-toast",
        });
      },
      ...mutationConfig,
    },
  });
};

export const useUpdateFeeScheduleScreening = (mutationConfig) => {
  const invalidateQueries = useInvalidateQueries();
  const { authToken } = useAuthContext();
  const { addToast } = useToast();
  const defaultMutationConfig = useDefaultMutationConfig();

  const jsonApiAdapter = getJsonApiAdapter(
    window.feConfig.CORE_API_BASE_URL,
    authToken,
  );

  return useUpdateRecord("fee_schedule_screening", jsonApiAdapter, {
    mutationConfig: {
      ...defaultMutationConfig,
      onSuccess: () => {
        invalidateQueries("fee_schedule_screening");
        addToast({
          message: "Fee Schedule Screening updated successfully",
          type: "success",
          dataTestId: "update-fee-schedule-screening-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error updating fee schedule screening: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "update-fee-schedule-screening-error-toast",
        });
      },
      ...mutationConfig,
    },
  });
};
