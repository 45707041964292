import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import CustomTable from "components/Tables/CustomTable";
import parse from "html-react-parser";
import { Button } from "@unite-us/app-components";
import { capitalize } from "lodash";
import EditMetafields from "./EditMetafields";
import { generateUUID } from "utils/generateUUID";

const CustomFieldsTable = ({ entity, updateEntity }) => {
  const editCustomFieldsModal = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openEditCustomFieldsModal = () => {
    setIsModalOpen(true);
  };
  const closeEditCustomFieldsModal = () => {
    setIsModalOpen(false);
  };

  // Will be modified in the future by https://uniteus.atlassian.net/browse/TOOL-1216
  useEffect(() => {
    if (isModalOpen) {
      editCustomFieldsModal.current?.openModal();
    } else {
      editCustomFieldsModal.current?.closeModal();
    }
  }, [isModalOpen]);

  const editCustomFieldsConfirmationHandler = async (newMetafields) => {
    const attachmentsMetafields = entity.metafields.filter(
      (metafield) => metafield?.type === "file",
    );
    const customFieldsMetafieldsPayload = newMetafields.map((metafield) => ({
      ...metafield,
      field: metafield.field || generateUUID(metafield.label),
    }));

    let response;
    try {
      response = await updateEntity(entity.id, {
        ...entity,
        metafields: [
          ...attachmentsMetafields,
          ...customFieldsMetafieldsPayload,
        ],
      });
    } catch (error) {
      console.error("Error updating custom fields", error);
    }
    response && closeEditCustomFieldsModal();
  };

  const customFields = entity.metafields
    ? entity.metafields.filter((metafield) => metafield?.type !== "file")
    : [];

  const customFieldsTableHeaders = [
    { label: "Name" },
    { label: "Type" },
    { label: "Requirement" },
    { label: "Description" },
  ];

  const customFieldsTableBody = customFields.map((customFields, index) => ({
    rowId: `custom-fields-${index}`,
    rowData: [
      { data: customFields.label },
      { data: capitalize(customFields.type) },
      {
        data:
          customFields.validations && customFields.validations.required
            ? "Required"
            : "Optional",
      },
      { data: customFields.help_text ? parse(customFields.help_text) : "" },
    ],
  }));

  return (
    <>
      <div className="flex justify-between align-middle py-2 px-4 bg-medium-fill-grey border border-b-0 border-light-border-blue">
        <h1 className="text-lg content-center">Custom Fields</h1>
        <Button
          id="edit-custom-fields-button"
          icon={{ name: "Edit", color: "text-blue", position: "left" }}
          className={
            "py-0 px-4 text-sm !text-text-blue rounded border solid border-med-pale-blue bg-white normal-case h-9"
          }
          label="Edit Custom Fields"
          data-testid="edit-custom-fields-button"
          onClick={openEditCustomFieldsModal}
        />
      </div>
      <CustomTable
        body={customFieldsTableBody}
        headers={customFieldsTableHeaders}
        isFetching={false}
        emptyTableMessage='Click "Edit Custom Fields" to add custom fields'
        dataTestId="custom-fields-table"
        className="!mt-0 !h-fit"
        emptyTableClassName="py-4"
      />
      {isModalOpen && (
        <EditMetafields
          metafields={customFields}
          modalRef={editCustomFieldsModal}
          confirmationHandler={editCustomFieldsConfirmationHandler}
          closeModal={closeEditCustomFieldsModal}
        />
      )}
    </>
  );
};

CustomFieldsTable.propTypes = {
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    metafields: PropTypes.arrayOf(
      PropTypes.shape({
        field: PropTypes.string,
        label: PropTypes.string.isRequired,
        type: PropTypes.string,
        help_text: PropTypes.string,
        validations: PropTypes.shape({
          required: PropTypes.bool,
        }),
      }),
    ),
  }).isRequired,
  updateEntity: PropTypes.func.isRequired,
};

export default CustomFieldsTable;
