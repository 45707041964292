import React from "react";
import { useNavigate, useParams } from "react-router";
import {
  useFindFeeScheduleScreening,
  useUpdateFeeScheduleScreening,
} from "api/core/feeScheduleScreeningHooks";
import { useFindFeeSchedule } from "api/core/feeScheduleHooks";
import constructFeeScheduleScreeningFormPayload from "../utils/constructFeeScheduleScreeningFormPayload";
import constructFeeScheduleScreeningInitialValues from "./utils/constructFeeScheduleScreeningInitialValues";
import FeeScheduleScreeningForm from "components/Forms/FeeScheduleScreeningForm";
import Spinner from "components/Spinner/Spinner";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

const EditFeeScheduleScreening = () => {
  const navigate = useNavigate();
  const { feeScheduleId, feeScheduleScreeningId } = useParams();
  const { updateRecord: updateFeeScheduleScreening } =
    useUpdateFeeScheduleScreening();
  const {
    data: feeScheduleScreening,
    isFetching: isFetchingScreening,
    isError: isErrorScreening,
  } = useFindFeeScheduleScreening(feeScheduleScreeningId);
  const {
    data: feeSchedule,
    isFetching: isFetchingFeeSchedule,
    isError: isErrorFeeSchedule,
  } = useFindFeeSchedule(feeScheduleId);
  const initialValues =
    constructFeeScheduleScreeningInitialValues(feeScheduleScreening);

  const isFetching = isFetchingScreening || isFetchingFeeSchedule;
  const isError = isErrorScreening || isErrorFeeSchedule;

  const handleSubmit = async (feeScheduleScreening) => {
    const payload = constructFeeScheduleScreeningFormPayload(
      feeScheduleScreening,
      feeScheduleId,
    );

    try {
      await updateFeeScheduleScreening(feeScheduleScreeningId, payload);
      navigate(
        `/fee-schedules/${feeScheduleId}/fee-schedule-screenings/${feeScheduleScreeningId}`,
      );
    } catch (error) {
      console.error("Error updating Fee Schedule Screening: ", error);
    }
  };

  return (
    <>
      {isFetching && (
        <Spinner
          className="h-screen"
          center
          dataTestId="edit-fee-schedule-screening-spinner"
        />
      )}
      {isError && (
        <ErrorMessage
          dataTestId="fee-schedule-screening-edit-error"
          errorText="Error Fetching Fee Schedule Screening"
          navigateUrl={`/fee-schedules/${feeScheduleId}/fee-schedule-screenings`}
          navigateText="Go Back to Fee Schedule Screenings Page"
        />
      )}
      {!isFetching && !isError && (
        <FeeScheduleScreeningForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          feeSchedule={feeSchedule}
        />
      )}
    </>
  );
};

export default EditFeeScheduleScreening;
