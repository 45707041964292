import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "@unite-us/app-components";
import FSSOrganizationsTable from "./FSSOrganizationsTable";
import ProgramsFromCBOTable from "./ProgramsFromCBOTable";
import { useUpdateFeeScheduleScreening } from "api/core/feeScheduleScreeningHooks";
import { useToast } from "@unite-us/app-components";
import { useInvalidateQueries } from "api/apiHooks.js";

const AddFSSProgramsModal = ({
  modalRef,
  closeModal,
  feeScheduleScreening,
  associatedPrograms,
}) => {
  const [isSelectingOrg, setIsSelectingOrg] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [selectedPrograms, setSelectedPrograms] = useState(new Set());
  const { addToast } = useToast();
  const invalidateQueries = useInvalidateQueries();
  const { updateRecord: updateFeeScheduleScreening } =
    useUpdateFeeScheduleScreening({
      onSuccess: () => {
        invalidateQueries("fee_schedule_screening");
        invalidateQueries("program");
        addToast({
          message: "Programs successfully associated to Screening",
          type: "success",
          dataTestId: "update-programs-association-to-screening-success-toast",
        });
      },
      onError: (error) => {
        addToast({
          message: `Error associating program to screening: ${error.message || "Unknown error"}`,
          type: "error",
          dataTestId: "update-programs-association-to-screening-error-toast",
        });
      },
    });

  const closeHandler = () => {
    setIsSelectingOrg(true);
    setSelectedOrg({});
    setSelectedPrograms(new Set());
    closeModal();
  };

  const addProgramsHandler = async () => {
    const selectedProgramObjects = Array.from(selectedPrograms, (id) => ({
      id,
    }));
    const updatedPrograms = {
      programs: [...feeScheduleScreening.programs, ...selectedProgramObjects],
    };

    try {
      await updateFeeScheduleScreening(
        feeScheduleScreening.id,
        updatedPrograms,
      );
      closeHandler();
    } catch (error) {
      console.error("Error associating programs to screening", error);
    }
  };

  const associatedProgramsIds = associatedPrograms.map((program) => program.id);

  const Footer = () => (
    <div className="flex justify-end space-x-6">
      <Button
        id="add-fss-programs-close-btn"
        className="text-sm text-brand-blue rounded border solid border-med-pale-blue bg-white normal-case"
        label="Cancel"
        data-testid="add-fss-programs-close-btn"
        onClick={closeHandler}
      />
      {isSelectingOrg ? (
        <Button
          id="add-fss-programs-next-btn"
          className="text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case"
          label="Next"
          data-testid="add-fss-programs-next-btn"
          disabled={!selectedOrg.id}
          onClick={() => setIsSelectingOrg(false)}
        />
      ) : (
        <Button
          id="add-fss-programs-add-btn"
          className="text-sm text-white rounded border solid border-med-pale-blue bg-brand-blue normal-case"
          label="Add Programs"
          data-testid="add-fss-programs-add-btn"
          disabled={selectedPrograms.size === 0}
          onClick={addProgramsHandler}
        />
      )}
    </div>
  );

  return (
    <Modal
      ref={modalRef}
      modalContentStyles="bg-medium-fill-grey overflow-y-hidden grid"
      title={`Add Programs`}
      data-testid="add-fss-programs-modal"
      spinnerShow={false}
      onRequestClose={closeHandler}
      showBorderRadius={true}
      disableScroll={true}
      modal
      actions={<Footer />}
      size="jumbo"
    >
      {isSelectingOrg ? (
        <FSSOrganizationsTable
          selectedOrg={selectedOrg}
          setSelectedOrg={setSelectedOrg}
          feeScheduleScreeningId={feeScheduleScreening.id}
        />
      ) : (
        <>
          <ProgramsFromCBOTable
            org={selectedOrg}
            selectedPrograms={selectedPrograms}
            setSelectedPrograms={setSelectedPrograms}
            associatedProgramsIds={associatedProgramsIds}
          />
        </>
      )}
    </Modal>
  );
};

AddFSSProgramsModal.propTypes = {
  modalRef: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  feeScheduleScreening: PropTypes.object.isRequired,
  associatedPrograms: PropTypes.array,
};

AddFSSProgramsModal.defaultProps = {
  associatedPrograms: [],
};

export default AddFSSProgramsModal;
