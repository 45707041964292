import moment from "moment";

const constructFeeScheduleScreeningInitialValues = (feeScheduleScreening) => {
  const initialValues = { ...feeScheduleScreening };

  if (initialValues && initialValues.starts_at) {
    initialValues.starts_at = moment
      .utc(initialValues.starts_at)
      .format("MM/DD/YYYY");
  }
  if (initialValues && initialValues.ends_at) {
    initialValues.ends_at = moment
      .utc(initialValues.ends_at)
      .format("MM/DD/YYYY");
  }

  return initialValues;
};

export default constructFeeScheduleScreeningInitialValues;
